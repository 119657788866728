<template>
    <div class="clearfix">
        <div class="fav-sidenav">
            <div class="favlist-title">
                <p>我的收藏夹</p>
            </div>
            <div class="fav-item" @click.stop="handleCommand('add',0,'')">
                <i class="iconfont ec-icon-circle-plus-outline"></i>
                <span>新建类别</span>
            </div>
            <ul>
                <li @click.stop class="fav-item" v-for=" item in userFavoritesList" :key="item.id" :class="{'cur':item.id==favId}">
                    <el-link :underline="false" :href='`#/people?fid=${item.id}`'>
                        <i class="iconfont ec-icon-folder"></i>
                        <span>{{item.name}}</span>
                        <span class="num">{{item.itemNum}}</span>
                    </el-link>
                    <div v-if='item.name!="默认收藏夹"' class="be-dropdown">
                        <el-dropdown  @command="handleCommand($event,item.id,item.name)">
                            <span class="el-dropdown-link">
                            <i class="iconfont ec-icon-more more " title='更多操作'></i>
                            </span>
                            <template #dropdown>
                            <el-dropdown-menu
                           
                            class="popper-dropdown">
                                <el-dropdown-item command="change">编辑信息</el-dropdown-item>
                                <el-dropdown-item command="del">删除</el-dropdown-item>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </li>
            </ul>
            <!-- 新增/修改 -->
            <el-dialog v-model="changeDialogVisible" title="收藏夹信息" width="40%">
                <div class="info-wrap">
                    <span style="margin-bottom:6px;display:inline-block;" >收藏夹名称</span>
                    <el-input v-model="selFavoritesName" maxlength="20" placeholder="收藏夹名称" show-word-limit type="text"/>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="changeDialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="changeUserFavoritesFun">确认</el-button>
                    </span>
                </template>
            </el-dialog>
            <!-- 删除 -->
            <el-dialog
                v-model="delDialogVisible"
                title="确认提示"
                width="30%">
                <span>{{delType==1?'确定删除这个收藏夹吗？':'你将要取消收藏 '+selFavList?.length+' 个指标'}}</span>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="delDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="delFun">确认</el-button>
                </span>
                </template>
            </el-dialog>
        </div>
        <div class="fav-main">
             <div  class="nameBox" style="padding-bottom:10px;">
                 <span class="txt-title">{{favName}}
                </span>
            </div>
            <div v-if='total>0'>
                <div  class="t-btn txt-r">
                    <el-link :underline="false" class="btn-link"  @click.prevent="handleCommand('delFavList','','')">取消收藏</el-link>
                </div>
                <el-table  :data="favList" stripe style="width: 100%" 
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60" />
                    <el-table-column prop="itemName" label="指标名"  />
                    <el-table-column prop="itemUnit" label="单位" width="180" />
                    <el-table-column prop="itemCycleName" label="更新周期" />
                    <el-table-column prop="itemGradeName" label="等级" />
                </el-table>
                <el-pagination
                class="page-cus"
                    @current-change="handleCurrentChangeFun"
                    :current-page="pageIndex"
                    :page-size='pageSize'
                    :hide-on-single-page="total<pageSize?true:false"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
             <el-empty v-if='total<1' description="该收藏夹还没有指标哦~" />
               
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import {getUserFavorites,getFavList,addUserFavorites,changeUserFavorites,delUserFavorites,delItemOfFavorites,addUserLog} from '@/http/basicsApi.js'
import { useRouter } from 'vue-router';
import { ElMessage  } from 'element-plus'

export default {
    setup() {
        let currentUser=ref(JSON.parse(localStorage.getItem('currentUser')));//当前用户信息
        const router =useRouter()
        let favId=ref(router.currentRoute.value.query.fid);
        let favName=ref('')//选择的收藏夹名称
        let changeDialogVisible=ref(false);//添加修改对话框是否显示
        let delDialogVisible=ref(false);//删除对话框是否显示
        let selFavoritesName=ref('');//收藏夹名称
        let selFavId=ref(0);//选中修改删除的收藏夹ID
        let favList=ref(null);
        let pageIndex=ref(1);//当前页
        let pageSize=ref(10);//每页显示记录数
        let total=ref(0);//总记录数
        let selFavList=ref(null);//选中的指标列表
        //获取用户的收藏夹
        let userFavoritesList=ref(null)
        const getUserFavoritesFun=async()=>{
            console.log(currentUser.value);
            const result=await getUserFavorites(currentUser.value?.userId);
            console.log(result);
            if(result && result.code==20000){
                userFavoritesList.value=result.data;
                //收藏夹指标项列表
                if(!favId.value){
                    favId.value=result.data.find(t=>t.name=="默认收藏夹").id;
                }
                let item=result.data.find(t=>t.id==favId.value);
                favName.value=item.name;
                if(item.itemNum>0){
                    pageIndex.value=1;
                    getFavListFun(favId.value)
                }
                else{
                    total.value=0;
                }
                
            }
        }
        //获取收藏夹的指标项列表
       
        const getFavListFun=async(favId)=>{
            let param={
                favId:parseInt( favId),
                pageSize:pageSize.value,
                pageIndex:pageIndex.value
            }
            const result=await getFavList(param);
            //console.log(result);
            if(result.code==20000){
                total.value=result.data.totalCount;
                favList.value=result.data.dataList;
                
            }
        }
        //指标项的复选框选中与取消选中
        const handleSelectionChange = (val) => {
            selFavList.value = val.map(t=>t.id);
        }
        //单击分页
        const handleCurrentChangeFun=(current)=>{
            pageIndex.value = current;
            getFavListFun(favId.value);
        }
        const handleCommand = (command,favId,favName) => {
            selFavId.value=favId;
            if(command=='add' || command=='change'){//添加/修改
                changeDialogVisible.value=true;
                selFavoritesName.value=favName;
            }
            else if(command=='del'){//删除
                delDialogVisible.value=true;
                delType.value=1;
            }
            else if(command=='delFavList'){//删除
                if(selFavList.value && selFavList.value.length>0){
                    delDialogVisible.value=true;
                    delType.value=2;
                }
                else{
                     ElMessage.warning({
                        message: '请选择要取消收藏的指标' 
                    }); 
                }
            }
        }
        //添加、修改收藏夹
        const changeUserFavoritesFun=async()=>{
            if(!selFavoritesName.value){
                ElMessage.error({
                    message: '收藏夹名称为空！', 
                });   
                return; 
            }
            //判断收藏夹名称长度
            else if(selFavoritesName.value.length>20 || selFavoritesName.value.length<=2){
                 ElMessage.error({
                    message: '名称在2到20个子之间', 
                });   
                return; 
            }
            let param={
                id:selFavId.value,
                name:selFavoritesName.value
            };
            if(selFavId.value==0){
                //添加
                const result=await addUserFavorites(param);
                console.log(result);
                if(result.code==20000){
                    changeDialogVisible.value=false;
                    userFavoritesList.value.push({
                        id: result.data,
                        itemNum: 0,
                        name: selFavoritesName.value
                    });
                }
            }
            else{
                //修改
                const result=await changeUserFavorites(param);
                console.log(result);
                if(result.code==20000){
                    changeDialogVisible.value=false;
                    let index = userFavoritesList.value.map((item) => item.id).indexOf(selFavId.value); //索引
                    let itemNum= userFavoritesList.value[index].itemNum;
                    let changeInfo={
                        id: selFavId.value,
                        itemNum: itemNum,
                        name: selFavoritesName.value
                    }
                    userFavoritesList.value.splice(index,1,changeInfo);
                    console.log(userFavoritesList.value);
                }
            }
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,selFavId.value==0?'添加收藏夹':'修改收藏夹',JSON.stringify(param));
        }
        //删除收藏夹
        let delType=ref(1);//删除类型，1：删除收藏夹，2：删除收藏夹中的指标项
        const delFun=async()=>{
            let param={
                ids:delType.value==1?selFavId.value.toString():selFavList.value.join(',')
            };
            if(delType.value==1){
                const result=await delUserFavorites(param);
                delDialogVisible.value=false;
                console.log(result.message);
                if(result.code==20000){
                    if(selFavId.value==favId.value){//删除项等于选中项，跳转到默认收藏夹
                        router.push(`/people`)
                    }
                    else{
                        let index = userFavoritesList.value.map((item) => item.id).indexOf(selFavId.value); //索引
                        userFavoritesList.value.splice(index,1);
                    }
                
                    ElMessage.success({
                        message: result.message, 
                    });   
                }
                else{
                    ElMessage.error({
                        message: result.message, 
                    });  
                }
            }
            else {
                const result=await delItemOfFavorites(param);
                delDialogVisible.value=false;
                //console.log(result.message);
                if(result.code==20000){
                    pageIndex.value=1;
                    getFavListFun(favId.value);
                    //将左侧的收藏夹的指标项数量值进行修改
                    let index= userFavoritesList.value.map((item) => item.id).indexOf(parseInt( favId.value)); //索引
                    let itemNum=userFavoritesList.value[index].itemNum;
                    console.log(index);
                    userFavoritesList.value[index].itemNum=itemNum-selFavList.value.length;
                    ElMessage.success({
                        message: result.message, 
                    });   
                }
                else{
                    ElMessage.error({
                        message: result.message, 
                    });  
                }
            }
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,delType.value==1?'删除收藏夹':'取消收藏',JSON.stringify(param));
        }
        //执行方法
        getUserFavoritesFun();
        //记录用户操作日志
        addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载',JSON.stringify({userId:currentUser.value.userId}));
        return {
            currentUser,
            favId,
            favName,
            selFavoritesName,
            userFavoritesList,
            favList,
            pageIndex,//当前页
            pageSize,//每页显示记录数
            total,
            handleCurrentChangeFun,
            handleSelectionChange,
            changeDialogVisible,
            delDialogVisible,
            handleCommand,
            changeUserFavoritesFun,
            delFun,
            delType,
            selFavList
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.favlist-title{
    padding-left: 20px;
    font-size: 16px;
    color: #99a2aa;
}
.fav-sidenav {
    position: relative;
    float: left;
    width: 180px;
    margin-right: -1px;
    font-size: 14px;
    box-sizing: border-box;

}
.fav-main {
    float: left;
    width: calc(100% - 180px);
    min-height: 600px;
    border-left: 1px solid $bordercolor;
    padding:0 20px 20px 20px;
}
.fav-item {
    position: relative;
    padding-left: 20px;
    transition: background-color .3s ease;
    white-space: nowrap;
    overflow: hidden;
    line-height: 44px;
    cursor: pointer;
   
    a{
        width: 100%;
        justify-content:left;
         color:inherit;
    }
}
.fav-sidenav .fav-item.cur,.fav-sidenav .fav-item.cur:hover {
    background: rgba($themeColor, 0.1);
}
.fav-sidenav .fav-item:hover {
    background-color: rgba($themeColor, 0.05);
    .num{
        display: none;
    }
    .be-dropdown{
        display: inline-block;
    }
    a{
        color:inherit;
    }
}
.fav-sidenav .num {
    display: inline-block;
    width: 32px;
    font-size: 12px;
    color: #99a2aa;
    vertical-align: middle;
    text-align: center;
    font-family: Arial;
    position:absolute;
    right: 0;
}
.more{
    display: inline-block;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.be-dropdown{
    width: 32px;
    position: absolute;
    right: 1px;
    text-align: center;
    z-index: 99999;
    
    display: none;
}
.btn-link{
  font-size:14px;
  vertical-align: text-bottom;
  &:hover{
      color: $specialtext-color;
  }
}
.btn-link.is-disabled {
    color: #aaa;
    cursor: not-allowed;
}

// .popper-dropdown {
//   min-width: 120px;
//   position: absolute !important;
//   top: 32px !important;
//   right: 0px !important;
// }
</style>